.banner_font_bold{
    font-size: 20px;
    font-family: 'MarkPro-Black SKY';
    line-height: 1.2;
}
.banner_font_reg{
    font-size: 20px;
    font-family: 'MarkPro-Book SKY';
    line-height: 1.2;
}
.ml-21{
  margin-left:5.3rem
}
.mh-120{
  min-height: 120px;
}
.md\:mh-142{
  min-height: 142px;
}