.w-148px{
  width:148px;
}
.w-102px{
  width:90px;
}
.h-22px{
  height:22px;
}
.h-34px{
  height:34px;
}
.padding_left_10{
  padding: 0 10px 0 0;
}
.h-125px{
  height:125px;
}
.padding_box{
  padding: 24px 0px 24px 10px;
}
.w-320px{
  width:320px;
}
.w-220px{
  width:220px;
}