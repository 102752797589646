@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro";
  src: url('/fonts/MarkPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MarkPro";
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/fonts/MarkPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Fresh chat overrides */
#fc_frame,
#fc_frame.fc-widget-small {
  bottom: 10% !important;
  right: 6px !important;
}

@media (min-width: 768px) {
  #fc_frame,
  #fc_frame.fc-widget-small {
    bottom: 8px !important;
    right: 6px !important;
  }
}

//Get SKY CSS

.w-920px{
  width:920px;
}
.w-910px{
  max-width:910px;
  @media only screen and (max-width: 767px) {
   max-width:325px;
}
}


.addbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width:910px;

}

@media (min-width: 768px) and (max-width: 1023px) {
  .addbox{
    max-width:920px;
  }
}
@media only screen and (max-width: 640px){
  .addbox{
  flex-direction:column;
  max-width:767px;
  gap:16px;
  align-items:center;
  }
}

.editbox{
  display: flex;
  flex-direction: row;
  max-width:910px;
}
  @media (min-width: 768px) and (max-width: 1023px) {
    .editbox{
    width:920px;
    }
  }
  @media only screen and (max-width: 640px){
    .editbox{
    flex-direction:column;
    max-width:767px;
    gap:16px;
    align-items:center;
    }
  }

.addboxbtn{
  min-width:12rem;
  margin-right:0px;
  margin-left:0px;
}
@media (max-width: 640px){
 .addboxbtn{
  min-width:325px;
 }
}
.cancel-upgrade-btn{
  min-width:12rem;
  margin-right:0px;
  margin-left:0px;
  --bg-opacity: 1;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--bg-opacity));
  background-color: #0D1137 !important;


}
@media (max-width: 640px){
 .cancel-upgrade-btn{
  min-width:325px;
 }
}
.cancel-upgrade-disabled{

  --bg-opacity: 1;
  background-color: #E0E0E0 !important;
  background-color: rgba(224, 224, 224, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #E0E0E0;
  border-color: rgba(224, 224, 224, var(--border-opacity));
  --text-opacity: 1;
  color: #4F4F4F !important;
  color: rgba(79, 79, 79, var(--text-opacity));
  border-radius: 10rem
}

.editboxbtn{
  min-width:12rem;
}
@media (max-width: 640px){
  .editboxbtn{
  min-width:325px;
  }
}

.w-858px{
  width:858px;
}
.w-564px{
  width:564px;
}
.w-448px{
  width:448px
}
.w-416px{
  width:416px
}
.w-280px{
  width:280px;
}
.w-270px{
  width:270px;
}
.w-325px{
  width:325px;
}
.w-540{
  width:540px;
}
.w-800px{
  width:800px;
}
.h-520px{
  height:520px;
}
.h-246px{
  height:246px
}
.h-325px{
  height: 325px!important;
}
.h-503px{
  height:  503px;
}
.h-547px{
  height:  547px;
}
.h-363px{
  height: 22.68rem;
}
.verticalBorder{
  height: 560px;
  margin-top: 3rem;
  border-color: #e2e8f0;
}
.verticalBorderUpgrade{
  height: 530px;
  margin-top: 3rem;
  border-color: #e2e8f0;
}
.mh-370px{
  min-height: 24.5rem;
}
.back_box{
  background:#FAFAFA;
}
.mt-4p{
  margin-top:4%
}
.left-30{
  left:30px;
}
.top-200{
  top:200px;
}
.top-239{
  top:239px;
}
.orBox{
  top:226px;
  width:23px;
  height:23px;
  z-index: 2;
}
.nick-name{
  width: 464px;
  height: 36px;
  padding: 0px 16px;
  border-radius: 6px;
  border: 1px solid var(--bw-sky-light-grey, #A6A6A6);
  background: var(--bw-white-100, #FFF);
}
.optional{
  flex: 1 0 0;
  color: var(--bw-sky-light-grey, #A6A6A6);
  font-size: 12px;
  font-family: 'MarkPro-Book SKY';
  font-weight: 450;
  line-height: 16px;
}

@media (min-width: 640px) {
  .scrollNone{
  overflow-x: inherit!important;
}
}

.-left-56px{
left:-56px;
}

.-right-40px{
right:-40px;
}



.right-12px{
right:12px;
}
.-left-20px{
left:-20px;
}
.txt-gray {
  color: var(--bw-sky-light-grey, #A6A6A6);
  font-family: 'MarkPro-Book SKY';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px;
}
.margin-120{
  margin-left: 40px;
  margin-right: 40px;
}
@media (min-width: 1024px) {
  .margin-120{
  margin-left: 120px;
  margin-right: 120px;
}
}

@media(min-width:768px){
.min-w-fit {
  min-width:fit-content!important;
}
}

.nsb-background {
  background: linear-gradient(90deg, #0d1137 0%, #364d9e 51.76%, #812dcd 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 900;
  min-width: max-content;
  font-family: MarkPro;
  font-size: 28px;
  line-height: 36px;
}
.max-w-100px{
  max-width:100px;
 }

.bill-styling{
  font-size: 32px;
  line-height: 36px;
  font-family: MarkPro;
}
.bg-pink-cool {
  --bg-opacity: 1;
  border-color: #f94cb1;
  background-color: #ffe0f1;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 100px !important;
 display: flex !important;
 flex-direction: row-reverse !important;
 width: 103px !important;
  height: 48px !important;
  padding: 14px 16px !important;
  font-size: 16px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}
.embeddedServiceHelpButton .helpButton {
  width: 164px !important;
  height: 48px !important;
  border-radius: 100px !important;
right:-45px !important;
}
.embeddedServiceHelpButton {
  position: fixed !important;
   left: 85% !important;



  }
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message{

 font-size: 0px !important;
}
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message::after{
 content: 'Chat';
font-size: 16px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}

@media (max-width: 520px) {
  .embeddedServiceHelpButton .helpButton .uiButton {

    padding: 8px 8px !important;
   justify-content: center !important;
   align-items: center !important;
    width: 93px !important;
   height:48px !important;
   margin: 0 auto;
   border-radius: 10px 10px 0px 0px !important;
   text-align: center !important;
/* Body Copy/Bold/M */
font-family: "Mark Pro" !important;
font-size: 10px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 125% !important;
flex-flow: row !important;
  }

  .embeddedServiceHelpButton .helpButton {
     width: 164px !important;
     height: 40px !important;
    transform: rotate(-90deg) !important;
    display: inline-flex !important;
    bottom: 120px !important;
    position: fixed !important;
    right: -60px !important;
  }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel .message{
    overflow: auto !important;
    transform:rotate(90deg) !important;
  },
  .embeddedServiceHelpButton .uiButton .helpButtonLabel{
    overflow: auto !important;

  }
  .embeddedServiceHelpButton .uiButton .embeddedServiceIcon{
    transform:rotate(90deg);
  }

}
@media only screen and (min-width: 520px) and (max-width: 1024px){
.embeddedServiceHelpButton .helpButton .uiButton{
  padding: 14px 16px !important;
}


}
@media only screen and (min-width: 325px) and (max-width: 1440px){

  .embeddedServiceHelpButton .embeddedServiceIcon::before {
    font-size: 24px !important;
  }
  .embeddedServiceHelpButton .embeddedServiceIcon{
    font-size: 24px !important;
  }
  }
.getsky-banner > div {
  padding: 12px 27px;
}
.getsky-banner-content p {
  color: white;
  font-size: 12px;
}
.getsky-banner-content p a {
  text-decoration: underline;
}
.getsky-banner-title {
  font-size: 22px;
}

@media (max-width: 768px) {
  .getsky-banner > div {
    padding: 28px 26px 21px 26px;
  }
  .getsky-banner-title {
    font-size: 28px;
  }
}

.modalOverlay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 12px;
  position:  fixed;
  min-width: 70vw;
  min-height:50vh;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
}

@media(max-width:768px){
  .modalOverlay{
    min-width:100vw;
  }
}

.border-red{
  border-color:rgba(243, 40, 54)
}
.leftalign{
  left:0.75rem;
}

@media(min-width:768px){
  .leftalign{
    left:3rem;
  }
}
.ReactModal__Overlay {
background-color: #686868 !important;
}

@media(max-width:768px){
  .ReactModal__Content{
  /* overflow: scroll; */
  inset:0px !important
  }
}

#react-autowhatever-1{
  z-index: 999!important;
}

.sky-input.sky-input--text {
  border-radius: 10px !important;
}
.sky-input--error_color{
  border-color:  #F32836 !important;

}
.text-error_color{
color:#F32836 !important;
}
.border_orange_color{
  border-color: #FF9E1C;

}
.border_green_color{
  border-color: #0BAA4B;

}